import { createIcon } from '@chakra-ui/icon';
import * as React from 'react';

export const QuestionIcon = createIcon({
  displayName: 'QuestionIcon',
  path: (
    <>
      <path
        d="M7.5 1.5C9.09135 1.5 10.6172 2.13222 11.7428 3.25725C12.8678 4.38284 13.5 5.90865 13.5 7.5C13.5 9.09135 12.8678 10.6172 11.7428 11.7428C10.6172 12.8678 9.09135 13.5 7.5 13.5C5.90865 13.5 4.38285 12.8678 3.25725 11.7428C2.13225 10.6172 1.5 9.09135 1.5 7.5C1.5 5.90865 2.13222 4.38285 3.25725 3.25725C4.38284 2.13225 5.90865 1.5 7.5 1.5ZM7.5 0C5.5107 0 3.603 0.790425 2.19675 2.19675C0.7905 3.60308 0 5.51085 0 7.5C0 9.48915 0.790425 11.397 2.19675 12.8033C3.60308 14.2095 5.51085 15 7.5 15C9.48915 15 11.397 14.2096 12.8033 12.8033C14.2095 11.3969 15 9.48915 15 7.5C15 5.51085 14.2096 3.603 12.8033 2.19675C11.3969 0.7905 9.48915 0 7.5 0V0Z"
        fill="currentColor"
      />
      <path
        d="M5.72192 6.18754H5.16117C5.1266 6.18754 5.10141 6.18754 5.10258 6.1395C5.10609 5.75805 5.18578 5.38129 5.33637 5.03091C5.54086 4.59379 5.89242 4.24224 6.32953 4.03833C7.03852 3.69555 7.85594 3.65512 8.59484 3.927C9.12159 4.09809 9.54522 4.49478 9.74972 5.00982C9.87452 5.33443 9.91847 5.68482 9.87862 6.02994C9.83995 6.45474 9.64425 6.84966 9.32959 7.13677C9.08935 7.36939 8.83155 7.58677 8.57959 7.80415V7.80474C8.38213 7.95181 8.22744 8.14869 8.12959 8.37427C8.06924 8.55181 8.03584 8.73756 8.03057 8.92506C8.03057 8.99127 8.02002 9.05865 8.01709 9.12427C8.01944 9.13833 8.01475 9.15181 8.00479 9.16177C7.99541 9.17173 7.98135 9.17583 7.96787 9.17407H6.9794C6.96651 9.17759 6.95362 9.17407 6.94366 9.16529C6.93429 9.1565 6.93018 9.14302 6.93253 9.13072C6.92784 8.80552 6.96124 8.4815 7.03155 8.16451C7.1294 7.83521 7.31867 7.53989 7.57764 7.31431C7.79972 7.10864 8.02764 6.90181 8.23155 6.68443V6.68384C8.41085 6.50455 8.53214 6.27602 8.58135 6.02701C8.66221 5.60572 8.5046 5.29342 8.10147 5.14634C7.71182 4.99693 7.27881 5.01275 6.90147 5.19029C6.64542 5.31568 6.46319 5.55357 6.40929 5.83365C6.38644 5.9274 6.37062 6.02232 6.36125 6.11841C6.36418 6.14068 6.35539 6.16294 6.33839 6.17759C6.32199 6.19224 6.29914 6.19751 6.27746 6.19224H5.7302L5.72192 6.18754Z"
        fill="currentColor"
      />
      <path
        d="M7.4912 9.89999C7.66874 9.8953 7.841 9.96502 7.96523 10.0922C8.08945 10.2193 8.15507 10.3928 8.14628 10.5703C8.14042 10.9347 7.84042 11.226 7.47596 11.2201C7.11151 11.2142 6.82088 10.9142 6.82675 10.5492C6.82382 10.374 6.89355 10.2047 7.01893 10.0822C7.1449 9.95914 7.31542 9.89352 7.49119 9.89997L7.4912 9.89999Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 15 15',
});
